<template>
  <AccountSharedLayout class="AccountLoginView" :show-language-switch="true" :show-plan="false" :show-legal="true">
    <span slot="title" v-t="'title'" />

    <template slot="main">
      <form @submit.prevent="loginWithEmail()">
        <FormGroup
          v-model.trim="email"
          name="username"
          :label="$t('account.formLabels.email')"
          :v="$v.email"
          type="email"
          autocomplete="off"
          @input="resetErrors()"
        />

        <FormGroup
          ref="password"
          v-model="password"
          name="password"
          :label="$t('account.formLabels.password')"
          type="password"
          :v="$v.password"
          autocomplete="off"
          @input="resetErrors()"
        />

        <div class="submitButtonContainer">
          <div v-show="error" class="errorBox"> <span v-t="'wrongCombo'" /> <a v-t="'forgot'" href="/forgot" /> </div>

          <SubmitButton class="submitButton" :label="$t('emailLoginButton')" :working="workingEmail" />
        </div>
      </form>

      <DividerWithText :label="$t('general.or')" />

      <div v-if="fbError" class="errorBox">
        <!-- <p class="alert bg-danger"> -->
        <!-- <span i18n="dashboard:loginView.facebook_error"></span> -->
        Got an error. Try again.
        <!-- </p> -->
      </div>

      <SubmitButton
        class="facebookButton button --global-clickable"
        :label="$t('signUp')"
        @click.native="gotoSignup()"
      />
    </template>

    <div slot="side">
      <div class="links">
        <div class="linksEntry">
          <span v-t="'signup'" class="linksQustion" />&nbsp;
          <!-- <div> -->
          <router-link
            v-t="'signupLinktext'"
            :to="{ name: 'AccountSignupView-basic', query: { next } }"
            tabindex="-1"
          />
          <!-- </div> -->
        </div>
        <!-- <br> -->
        <div class="linksEntry">
          <span v-t="'forgot'" class="linksQustion" />&nbsp;
          <!-- <div> -->
          <router-link v-t="'forgotLinktext'" :to="{ name: 'AccountForgotView' }" tabindex="-1" />
          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- <div slot="footer">FOOTER</div> -->
  </AccountSharedLayout>
</template>

<translations>
  title: 'Log in'
  title_no: 'Logg inn'

  forgot: 'Forgot your password?'
  forgot_no: 'Glemt passordet?'
  forgotLinktext: 'Reset it here'
  forgotLinktext_no: 'Nullstill her'

  wrongCombo: 'Wrong email/password combination.'
  wrongCombo_no: 'Feil brukernavn eller passord'

  signup: 'Don''t have an account?'
  signup_no: 'Ikke medlem?'
  signupLinktext: 'Get a trial membership now'
  signupLinktext_no: 'Ta et prøvemedlemskap nå'

  facebookLoginButton: 'Login with Facebook'
  facebookLoginButton_no: 'Logg inn med Facebook'

  emailLoginButton: 'Login'
  emailLoginButton_no: 'Logg inn'

  signUp: 'Sign up'
  signUp_no: 'Meld deg inn'
</translations>

<script>
import AccountSharedLayout from '../components/AccountSharedLayout'
import FormGroup from '../components/FormGroup'
import SubmitButton from '../components/SubmitButton'
import { required, email } from 'vuelidate/lib/validators'
import DividerWithText from '../components/DividerWithText'

export default {
  components: { AccountSharedLayout, FormGroup, SubmitButton, DividerWithText },
  data() {
    return {
      workingEmail: false,
      workingFacebook: false,
      email: '',
      password: '',
      error: false,
      fbError: false,
    }
  },
  computed: {
    working() {
      return this.workingEmail || this.workingFacebook
    },

    next() {
      return this.$route.query.next || '/'
    },
  },
  created() {},
  methods: {
    async loginWithEmail() {
      this.$nextTick() // workaround for chrome autofill
        .then(async () => {
          this.workingEmail = true
          try {
            await this.$store.dispatch('moduleAuth/login', {
              username: this.email,
              password: this.password,
            })
          } catch (err) {
            this.error = true
            this.workingEmail = false
            return
          }
          this.$router.push({
            path: this.next,
          })
        })
    },
    async loginWithFacebook() {
      // this.fbError = true
      this.workingFacebook = true
      try {
        await this.$store.dispatch('moduleAuth/authenticate', 'facebook')
      } catch (err) {
        this.fbError = true
        return
      } finally {
        this.workingFacebook = false
      }
      this.$router.push({ path: this.next })
    },
    resetErrors() {
      this.error = false
      this.fbError = false
    },
    gotoSignup() {
      this.$router.push({ name: 'AccountSignupView-basic', query: { next: this.next } })
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
}
</script>

<style lang="scss" scoped>
.AccountLoginView {
  // padding: 1em;
}
.submitButtonContainer {
  margin-top: 3em;
}
.submitButton {
  width: 100%;
}
.errorBox {
  padding: 1em;
  background-color: #edc8c5;
  color: #c38a8a;
  margin-bottom: 1em;
}
.FormGroup {
  &:not(:first-child) {
    margin-top: 1em;
  }
}
.button {
  background-color: #3b7098;
  color: white;
  padding: 1em 2em;
  border-radius: 2em;
  text-align: center;
}
.links {
  a {
    text-decoration: none;
    color: #5fcf80;
  }
}
.linksEntry {
  line-height: 1.5em;
  &:not(:first-child) {
    margin-top: 1em;
  }
}
.linksQustion {
  color: rgba(black, 0.7);
  // margin-bottom: 0.5em;
}
.facebookButton {
  width: 100%;
}

@include media('>=600px') {
  .linksQustion {
    display: block;
  }
}
</style>
