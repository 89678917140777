<template>
  <div class="DividerWithText">
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.DividerWithText {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: rgba(black, 0.5);
    margin: 1em 2.5em;

    &::before,
    &::after {
        content: '';
        height: 1px;
        background-color: rgba(black, 0.1);
        flex: 1;
    }
}
.label {
    margin: 0 1em;
}
</style>
